import React from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';
import Header from "./header/Header";
import HomePage from "./home_page/HomePage";
import SectionPage from "./section_page/SectionPage";
import Footer from "./footer/Footer";
import DataPage from "./data_page/DataPage";
import ContactPage from "./contact/Contact";
import DataAnalysisPage from "./data_analysis_page/DataAnalysisPage";


const MainContainer = React.memo(() => {

    return (
        <div className="App">
            <Header/>
            <div className="content">
                <Routes>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/section" element={<SectionPage/>}/>
                    <Route path="/data" element={<DataPage/>}/>
                    <Route path="/contact" element={<ContactPage/>}/>
                    <Route path="/analysis" element={<DataAnalysisPage/>}/>
                    <Route path="/account" element={<DataPage/>}/>
                    <Route path="/weather" element={<DataPage/>}/>
                    <Route path="/register" element={<DataPage/>}/>
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
            </div>
            <Footer/>
        </div>
    );
});

export default MainContainer;
